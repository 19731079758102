// Import only the necessary Bootstrap JS components
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

// Import Bootstrap SCSS for full styles, or selective imports for smaller builds
import '../css/custom.scss';
import '../css/custom.css';

// Import all chart.js components

// import 'chart.js/auto';

// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);

import Chart from 'chart.js/auto';
window.Chart = Chart

import '@popperjs/core';



